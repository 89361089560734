<template>
  <div>
    <div class="page-title">
      <h3>{{$t('profilePage.Profile')}}</h3>
    </div>

    <form class="form" @submit.prevent="updateProfile">
      <div class="input-field">
        <input
          id="name"
          type="text"
          v-model="name"
          :class="{invalid: $v.name.$dirty && !$v.name.required}"
        >
        <label for="name">{{$t('profilePage.UserName')}}</label>
        <small
          v-if="($v.name.$dirty && !$v.name.required)"
          class="helper-text invalid"
        >{{$t('profilePage.InputName')}}</small>
      </div>

      <p>
        <label>
          <input v-model="lang" class="with-gap" value="ru" type="radio" />
          <span>Русский</span>
        </label>
      </p>
      <p>
        <label>
          <input v-model="lang" class="with-gap" value="en" type="radio" />
          <span>English</span>
        </label>
      </p>
      <p>
        <label>
          <input v-model="lang" class="with-gap" value="uk" type="radio"  />
          <span>Українська</span>
        </label>
      </p>

      <button class="btn waves-effect waves-light" type="submit">
        {{$t('global.Update')}}
        <i class="material-icons right">send</i>
      </button>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Profile',
  data () {
    return {
      name: '',
      lang: ''
    }
  },
  metaInfo () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Profile'
    }
  },
  computed: {
    ...mapGetters(['info'])
  },
  mounted () {
    this.name = this.info.name
    this.lang = this.info.lang
    setTimeout(() => {
      M.updateTextFields()
    })

    this.$i18n.locale = this.lang
  },
  validations: {
    name: { required }
  },
  methods: {
    ...mapActions(['updateInfo']),
    async updateProfile () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      try {
        await this.updateInfo({
          name: this.name,
          lang: this.lang
        })
        this.$i18n.locale = this.lang
      } catch (e) {}
    }
  }
}
</script>

<style scoped>

</style>
